import { Result, Button } from "antd"
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"

import { useAuth } from "../../context/AuthContext"
import axiosInstance from "../../utils/axios.util"
export default function PageForGuest() {
  const { user, logOut } = useAuth()
  const [userLogin, setUserLogin] = useState({})
  useEffect(() => {
    axiosInstance.get("/user/me").then((res) => {
      setUserLogin(res.data)
    })
  }, [user])
  const handleLogout = async () => {
    try {
      await logOut()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="App">
      <Result
        status="403"
        title="Giaodichchina.vn"
        subTitle={
          <div style={{ color: "black" }}>
            <p>
              Bạn đã đăng nhập thành công vào hệ thống với email{" "}
              <strong>{userLogin.email}</strong>
            </p>
            <p>
              Quyền truy cập hiện tại: <strong>{userLogin.role}</strong>
            </p>
            <p>
              Bạn chưa đủ quyền để sử dụng dịch vụ trong hệ thống! <br /> Vui
              lòng liên hệ với Admin theo số điện thoại:{" "}
              <strong>08.5566.8484 (call/ zalo)</strong> để kích hoạt tài khoản.
            </p>
            <p>
              <strong>Xin cảm ơn!</strong>
            </p>
          </div>
        }
        extra={
          <Link to={"/login"}>
            {" "}
            <Button type="primary" onClick={handleLogout}>
              Đăng xuất
            </Button>
          </Link>
        }
      />
    </div>
  )
}
