import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd"
import React, { useEffect, useState } from "react"
import axiosInstance from "../../utils/axios.util"
import {
  DollarCircleFilled,
  EditFilled,
  ShoppingCartOutlined,
  TransactionOutlined,
} from "@ant-design/icons"
import UserDisplay from "../../components/dashboard/UserDisplay"

const { Option } = Select

export default function UsersList() {
  const [form] = Form.useForm()
  const [usersList, setUsersList] = useState([])
  const [updateUserModal, setUpdateUserModal] = useState(false)
  const [chargeModal, setChargeModal] = useState(false)
  const [updateAccountModal, setUpdateAccountModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [userRate, setUserRate] = useState(0)
  const [chargeAmount, setChargeAmount] = useState(0)
  const [chargeNote, setChargeNote] = useState("")
  const [accountList, setAccountList] = useState([])
  const [selectedAccounts, setSelectedAccounts] = useState([])

  const chargeRequest = {
    amount: chargeAmount,
    note: chargeNote,
  }
  const loadUserList = () => {
    axiosInstance
      .get("/users")
      .then((res) => {
        setUsersList(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => {
    if (selectedUser) {
      setUserRate(selectedUser.exchangeRate)
      setSelectedAccounts(selectedUser.accounts || [])
    }
  }, [selectedUser])
  useEffect(() => {
    loadUserList()
  }, [])
  useEffect(() => {
    axiosInstance
      .get("/accounts")
      .then((res) => {
        setAccountList(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (value, record) => (
        <UserDisplay
          uid={record.uid}
          userInfo={record}
          displayName={false}
          displayRole={false}
        />
      ),
    },
    {
      title: "Tên",
      dataIndex: "displayName",
      key: "displayName",
      render: (value) => <span>{value}</span>,
    },
    {
      title: "Tỷ giá",
      dataIndex: "exchangeRate",
      key: "exchangeRate",
      render: (value) => <span>{value ? value : "Chưa cập nhật"}</span>,
    },
    {
      title: "Số dư",
      dataIndex: "balance",
      key: "balance",
      render: (value) => (
        <span>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "VND",
          }).format(value)}
          {""}
        </span>
      ),
    },

    {
      title: "Trạng thái",
      dataIndex: "active",
      key: "active",
      render: (value, user) => (
        <span>
          {user.role === "guest" ? (
            <Tag color="orange">Chưa kích hoạt</Tag>
          ) : (
            <Tag color="green">Đã kích hoạt</Tag>
          )}
        </span>
      ),
    },
    {
      title: "Vai trò",
      dataIndex: "role",
      key: "role",
      render: (value) => (
        <span>
          {value === "admin" ? (
            <Tag color="#108ee9">Admin</Tag>
          ) : value === "staff" ? (
            <Tag color="#87d068">Nhân viên</Tag>
          ) : value === "customer" ? (
            <Tag color="#f50">Khách hàng</Tag>
          ) : (
            <Tag color="magenta">Guest</Tag>
          )}
        </span>
      ),
    },
    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      render: (value, user) => (
        <div className="d-flex">
          <Tooltip placement="top" title="Cập nhật">
            <Button
              type="primary"
              ghost
              onClick={() => showModalUpdateUser(user)}
            >
              <EditFilled style={{ fontSize: "16px" }} />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="Nạp tiền">
            <Button type="primary" ghost onClick={() => showChargeModal(user)}>
              <DollarCircleFilled style={{ fontSize: "16px" }} />
            </Button>
          </Tooltip>
          {/* <Tooltip placement="top" title="Rút tiền">
            <Button type="primary" ghost>
              <MoneyCollectFilled style={{ fontSize: "16px" }} />
            </Button>
          </Tooltip> */}
          <Tooltip placement="top" title="Lịch sử giao dịch">
            <Button type="primary" ghost>
              <TransactionOutlined style={{ fontSize: "16px" }} />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="Nick mua hàng">
            <Button
              type="primary"
              ghost
              onClick={() => showUpdateAccountModal(user)}
            >
              <ShoppingCartOutlined style={{ fontSize: "16px" }} />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ]
  const onFinish = (values) => {
    console.log(values)
  }
  const handleUpdateUser = () => {
    axiosInstance
      .patch(`/users/${selectedUser.uid}/rate`, {
        rate: userRate,
      })
      .then((res) => {
        message.success("Cập nhật tỷ giá người dùng thành công")
        setUpdateUserModal(false)

        setSelectedUser(null)
        loadUserList()
      })
      .catch((err) => {
        console.log(err)
      })
    axiosInstance
      .patch(`/users/${selectedUser.uid}/role`, {
        role: selectedUser.role,
      })
      .then((res) => {
        message.success("Cập nhật quyền người dùng thành công")
        setUpdateUserModal(false)

        setSelectedUser(null)
        loadUserList()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleChargeRequest = () => {
    axiosInstance
      .post(`/users/${selectedUser.uid}/top-up`, chargeRequest)
      .then((res) => {
        message.success("Nạp tiền thành công")
        setChargeModal(false)
        loadUserList()
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setChargeAmount(0)
        setChargeNote("")
      })
  }
  const handleUpdateUserAccount = () => {
    axiosInstance
      .put(`/users/${selectedUser.uid}/accounts`, {
        accounts: selectedAccounts,
      })
      .then((res) => {
        message.success("Cập nhật nick mua hàng thành công")
        setUpdateAccountModal(false)
        loadUserList()
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setSelectedAccounts(null)
      })
  }
  const handleCancel = () => {
    setUpdateUserModal(false)
    setChargeModal(false)
    setUpdateAccountModal(false)
  }
  const showModalUpdateUser = (user) => {
    setSelectedUser(user)
    setUpdateUserModal(true)
  }
  const showChargeModal = (user) => {
    setSelectedUser(user)
    setChargeModal(true)
  }
  const showUpdateAccountModal = (user) => {
    setSelectedUser(user)
    setUpdateAccountModal(true)
  }
  const handleSelectedCheckbox = (e) => {
    const index = selectedAccounts.indexOf(e.target.value)
    if (index > -1) {
      selectedAccounts.splice(index, 1)
    } else {
      selectedAccounts.push(e.target.value)
    }
    setSelectedAccounts([...selectedAccounts])
  }

  return (
    <>
      <Card title="DANH SÁCH NGƯỜI DÙNG">
        <Form
          layout="inline"
          form={form}
          style={{ display: "flex", justifyContent: "center" }}
          onFinish={onFinish}
        >
          <Form.Item label="Tên người dùng">
            <Input placeholder="Nhập tên người dùng" />
          </Form.Item>

          <Form.Item label="Email">
            <Input placeholder="Nhập email người dùng" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {" "}
              Tìm kiếm
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <div className="mb-20">
        <Card>
          <Table
            columns={columns}
            dataSource={usersList}
            rowKey="uid"
            pagination={{ pageSize: 10 }}
          />
        </Card>
      </div>
      <Modal
        title="Cập nhật người dùng"
        visible={updateUserModal}
        onOk={handleUpdateUser}
        onCancel={handleCancel}
        okText="Cập nhật"
        cancelText="Hủy"
      >
        <Form layout="vertical">
          <Form.Item label="Email">
            <Input
              placeholder="Nhập email người dùng"
              value={selectedUser?.email}
              disabled
            />
          </Form.Item>
          <Form.Item label="Tỷ giá">
            <Input
              placeholder="Nhập tỷ giá"
              value={userRate}
              onChange={(e) => setUserRate(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Vai trò">
            <Select
              placeholder="Chọn vai trò"
              value={selectedUser?.role}
              onChange={(value) =>
                setSelectedUser({ ...selectedUser, role: value })
              }
            >
              <Option value="admin">Admin</Option>
              <Option value="staff">Nhân viên</Option>
              <Option value="customer">Customer</Option>
              <Option value="guest">Guest</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Nạp tiền VNĐ"
        visible={chargeModal}
        onOk={handleChargeRequest}
        onCancel={handleCancel}
        okText="Nạp tiền"
        cancelText="Hủy"
      >
        <Form layout="vertical">
          <Form.Item label="Email">
            <Input
              placeholder="Nhập email người dùng"
              value={selectedUser?.email}
              disabled
            />
          </Form.Item>
          <Form.Item label="Số tiền cần nạp">
            <InputNumber
              prefix="VNĐ"
              style={{
                width: "100%",
              }}
              placeholder="Số tiền VNĐ"
              value={chargeAmount}
              onChange={(value) => {
                setChargeAmount(value)
              }}
            />
          </Form.Item>
          <Form.Item label="Nội dung">
            <Input
              placeholder="Nội dung nạp tiền"
              value={chargeNote}
              onChange={(e) => setChargeNote(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Cập nhật Nick mua hàng"
        visible={updateAccountModal}
        onOk={handleUpdateUserAccount}
        onCancel={handleCancel}
        okText="Cập nhật"
        cancelText="Hủy"
      >
        <Form layout="vertical">
          <Form.Item label="Email">
            <Input
              placeholder="Nhập email người dùng"
              value={selectedUser?.email}
              disabled
            />
          </Form.Item>
          <Form.Item label="Chọn Nick mua hàng:">
            <Checkbox.Group value={selectedAccounts}>
              {accountList.map((account) => (
                <Row key={account.username}>
                  <Col span={24}>
                    <Checkbox
                      value={account.username}
                      onChange={handleSelectedCheckbox}
                    >
                      {account.username}
                    </Checkbox>
                  </Col>
                </Row>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
