import React, { useEffect } from "react"
import GoogleButton from "react-google-button"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../context/AuthContext"

export default function LoginPage() {
  const { googleSignIn, user } = useAuth()

  const navigate = useNavigate()
  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn()
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (user !== null) {
      navigate("/")
    }
  }, [user])

  return (
    <div className="App">
      <header className="App-header">
        <h2>Giaodichchina.vn</h2>
        <div>
          <GoogleButton onClick={handleGoogleSignIn}>
            Đăng nhập với tài khoản Google
          </GoogleButton>
        </div>
      </header>
    </div>
  )
}
