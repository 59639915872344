import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd"
import React, { useEffect } from "react"
import axiosInstance from "../../utils/axios.util"
import {
  AlibabaOutlined,
  DeleteFilled,
  EditFilled,
  PlusCircleOutlined,
  TaobaoCircleOutlined,
} from "@ant-design/icons"
import UserDisplay from "../../components/dashboard/UserDisplay"

const { Option } = Select
export default function AccountList() {
  const [accounts, setAccounts] = React.useState([])
  const [showAddAccountModal, setShowAddAccountModal] = React.useState(false)
  const [showEditAccountModal, setShowEditAccountModal] = React.useState(false)
  const [accountUsername, setAccountUsername] = React.useState("")
  const [accountPassword, setAccountPassword] = React.useState("")
  const [updateAccountPassword, setUpdateAccountPassword] = React.useState("")
  const [updateMarket, setUpdateMarket] = React.useState("")
  const [accountMarkets, setAccountMarkets] = React.useState([])
  const [selectedAccount, setSelectedAccount] = React.useState({})
  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (text, record, index) => index + 1,
      align: "center",
      width: "5%",
      fixed: "left",
      className: "text-center",
    },
    {
      title: "Tên tài khoản",
      dataIndex: "username",
      key: "username",
      render: (text, record) => <span>{record.username}</span>,
      align: "center",
      width: "20%",
      fixed: "left",
      className: "text-center",
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      render: (text, record) => <span>{record.password}</span>,
      align: "center",
      width: "20%",
      fixed: "left",
      className: "text-center",
    },
    {
      title: "Trang TMĐT hỗ trợ",
      dataIndex: "marketSupports",
      key: "marketSupports",
      render: (text, record) => (
        <span>
          {record.marketSupports[0] === "taobao" ? (
            <TaobaoCircleOutlined
              style={{ color: "#FF4002", fontSize: "20px" }}
            />
          ) : (
            <div></div>
          )}
          {record.marketSupports[1] === "1688" ? (
            <AlibabaOutlined
              style={{ color: "#FF4002", fontSize: "20px", marginLeft: "12px" }}
            />
          ) : (
            <div></div>
          )}
        </span>
      ),
      align: "center",
      width: "20%",
      fixed: "left",
      className: "text-center",
    },
    {
      title: "Tài khoản đang sử dụng",
      dataIndex: "users",
      key: "users",
      render: (text, record) => (
        <div
          style={{ display: "flex", justifyContent: "flex-start", gap: "10px" }}
        >
          {text?.map((user, index) => {
            return (
              <Tooltip key={user.uid} placement="top" title={user.email}>
                <div style={{ width: "40px", height: "40px" }}>
                  <UserDisplay
                    userInfo={user}
                    uid={user.uid}
                    displayRole={false}
                    displayName={false}
                    displayEmail={false}
                  />
                </div>
              </Tooltip>
            )
          })}
        </div>
      ),
      align: "center",
      width: "20%",
      fixed: "left",
      className: "text-center",
    },
    {
      title: "Thao tác",
      dataIndex: "",
      key: "action",
      render: (text, account) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button type="primary" onClick={() => handleShowUpdateModal(account)}>
            <EditFilled />
          </Button>
          <Popconfirm
            title="Bạn có chắc chắn xóa tài khoản này?"
            placement="top"
            okText="Đồng ý"
            cancelText="Hủy"
            onConfirm={() => deleteAccount(account.username)}
          >
            <Button type="danger">
              <DeleteFilled />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ]
  const loadAccountsList = () => {
    axiosInstance
      .get("/accounts?displayUser=true")
      .then((res) => {
        setAccounts(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => {
    loadAccountsList()
  }, [])

  const handleShowModal = () => {
    setShowAddAccountModal(true)
  }
  const handleShowUpdateModal = (order) => {
    setShowEditAccountModal(true)
    setSelectedAccount(order)
    setUpdateAccountPassword(order.password)
    setUpdateMarket(order.marketSupports)
  }
  const handleCloseModal = () => {
    setShowAddAccountModal(false)
    setShowEditAccountModal(false)
  }
  const handleSelectedCheckbox = (e) => {
    const index = accountMarkets.indexOf(e.target.value)
    if (index > -1) {
      accountMarkets.splice(index, 1)
    } else {
      accountMarkets.push(e.target.value)
    }
    setAccountMarkets([...accountMarkets])
  }
  const handleUpdateSelctedCheckbox = (e) => {
    const index = updateMarket.indexOf(e.target.value)
    if (index > -1) {
      updateMarket.splice(index, 1)
    } else {
      updateMarket.push(e.target.value)
    }
    setUpdateMarket([...updateMarket])
  }

  const handleAddAccount = () => {
    axiosInstance
      .post("/accounts", {
        username: accountUsername,
        password: accountPassword,
        marketSupports: accountMarkets,
      })
      .then((res) => {
        setAccounts([...accounts, res.data])
        message.success("Thêm tài khoản thành công")
        setShowAddAccountModal(false)
        loadAccountsList()
      })
  }
  const handleUpdateAccount = () => {
    axiosInstance
      .put(`/accounts/${selectedAccount.username}`, {
        password: updateAccountPassword,
        marketSupports: updateMarket,
      })
      .then((res) => {
        message.success("Cập nhật mật khẩu thành công")
        setShowEditAccountModal(false)
        loadAccountsList()
      })
      .catch((err) => {
        message.error("Cập nhật mật khẩu thất bại")
      })
  }
  const deleteAccount = (username) => {
    axiosInstance
      .delete(`/accounts/${username}`)
      .then((res) => {
        message.success("Xóa tài khoản thành công")
        loadAccountsList()
      })
      .catch((err) => {
        message.error("Xóa tài khoản thất bại")
      })
  }

  return (
    <>
      <Card title="DANH SÁCH TÀI KHOẢN MUA HÀNG">
        <div style={{ marginBottom: "20px" }}>
          <Button type="primary" onClick={handleShowModal}>
            <PlusCircleOutlined /> Thêm tài khoản
          </Button>
        </div>
        <div>
          <Table
            columns={columns}
            dataSource={accounts}
            rowKey={(record) => record.username}
          />
        </div>
      </Card>
      {/* Modal add new Account */}
      <Modal
        title="Thêm tài khoản"
        visible={showAddAccountModal}
        onCancel={handleCloseModal}
        footer={[
          <Button key="back" onClick={handleCloseModal}>
            Hủy
          </Button>,
          <Button key="submit" type="primary" onClick={handleAddAccount}>
            Thêm
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Tên tài khoản">
            <Input
              type="text"
              value={accountUsername}
              onChange={(e) => setAccountUsername(e.target.value)}
            />
          </Form.Item>

          <Form.Item label="Mật khẩu">
            <Input
              type="text"
              value={accountPassword}
              onChange={(e) => setAccountPassword(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Trang TMĐT hỗ trợ">
            <Checkbox.Group value={accountMarkets}>
              <Row>
                <Col span={24}>
                  <Checkbox value="taobao" onChange={handleSelectedCheckbox}>
                    Taobao
                  </Checkbox>
                  <Checkbox value="1688" onChange={handleSelectedCheckbox}>
                    1688
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </Modal>

      {/* Modal Update Account */}
      <Modal
        title="Cập nhật tài khoản"
        visible={showEditAccountModal}
        onCancel={handleCloseModal}
        footer={[
          <Button key="back" onClick={handleCloseModal}>
            Hủy
          </Button>,
          <Button type="primary" onClick={handleUpdateAccount}>
            Cập nhật
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Tên tài khoản">
            <Input type="text" value={selectedAccount.username} disabled />
          </Form.Item>

          <Form.Item label="Mật khẩu">
            <Input
              type="text"
              value={updateAccountPassword}
              onChange={(e) => setUpdateAccountPassword(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Trang TMĐT hỗ trợ">
            <Checkbox.Group value={updateMarket}>
              <Row>
                <Col span={24}>
                  <Checkbox
                    value="taobao"
                    onChange={handleUpdateSelctedCheckbox}
                  >
                    Taobao
                  </Checkbox>
                  <Checkbox value="1688" onChange={handleUpdateSelctedCheckbox}>
                    1688
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
