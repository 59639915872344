import { useContext, createContext, useEffect, useState } from "react"
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
} from "@firebase/auth"

import { auth } from "../firebase"
import axiosInstance from "../utils/axios.util"

const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(auth.currentUser)
  const [isLoading, setIsLoading] = useState(true)
  const [userProfile, setUserProfile] = useState(null)
  console.log("user", auth.currentUser)
  const googleSignIn = () => {
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider)
  }

  const logOut = () => {
    signOut(auth)
  }

  useEffect(() => {
    const isLogin = onAuthStateChanged(auth, (currentUser) => {
      setIsLoading(false)
      setUser(currentUser)
    })
    return () => {
      isLogin()
    }
  }, [])

  useEffect(() => {
    if (user) {
      axiosInstance.get(`/user/me`).then((res) => {
        setUserProfile(res.data)
      })
    }
  }, [user])

  return (
    <AuthContext.Provider value={{ googleSignIn, logOut, user, userProfile }}>
      {isLoading ? <div>Loading...</div> : children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
