import React, { useEffect, useState } from "react"
import { Avatar, Layout, Menu } from "antd"

import { useLocation } from "react-router-dom"
import menuItems from "../SidebarMenu/menuItems"
import { useAuth } from "../../context/AuthContext"

const { Sider } = Layout

const subMenuKeys = menuItems
  .filter((item) => !!item.children)
  .map((item) => item.key)

const hasRoles = (roles, user) => {
  if (!roles) return true
  if (!user) return false
  return roles.includes(user.role)
}

export default function LayoutSider() {
  const { pathname } = useLocation()
  const [collapsed, setCollapsed] = useState(false)
  const [openKeys, setOpenKeys] = useState(
    subMenuKeys.filter((key) => pathname.includes(key))
  )
  const [selectedKeys, setSelectedKey] = useState([pathname])
  const { userProfile } = useAuth()

  useEffect(() => {
    setSelectedKey([pathname])
    setOpenKeys(subMenuKeys.filter((key) => pathname.includes(key)))
  }, [pathname])

  const onCollapse = () => {
    setCollapsed(!collapsed)
  }
  return (
    <Sider
      width={240}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <div
        className="logo"
        style={{
          height: "32px",
          margin: "16px",
          color: "#fff",
          fontSize: "16px",
          fontWeight: "700",
        }}
      >
        <Avatar
          size={42}
          src={"/logo_128x128.png"}
          alt={"GiaoDichChina logo"}
          style={{
            marginRight: "8px",
          }}
        />
        <span>GiaoDichChina</span>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        items={menuItems.filter((item) => hasRoles(item.roles, userProfile))}
        onOpenChange={(openKeys) => setOpenKeys(openKeys)}
        openKeys={openKeys}
        selectedKeys={selectedKeys}
      />
    </Sider>
  )
}
