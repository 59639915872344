import { Select, Spin } from "antd"
import debounce from "lodash.debounce"
import React, { useEffect, useMemo, useRef, useState } from "react"
import axiosInstance from "../utils/axios.util"

export default function UserSelect({
  fetchOptions,
  debounceTimeout = 800,
  ...props
}) {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])
  useEffect(() => {
    debounceFetcher()
  }, [debounceFetcher])
  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  )
} // Usage of DebounceSelect

export async function fetchUserList(username) {
  return axiosInstance
    .get(`/users`)
    .then((response) => response.data)
    .then((users) =>
      users
        .filter((user) => user.email.includes(username))
        .map((user) => ({
          label: `${user.email} `,
          value: user.uid,
        }))
    )
}
