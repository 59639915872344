import React, { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Radio,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd"
import {
  AlibabaOutlined,
  EditOutlined,
  RedoOutlined,
  SyncOutlined,
  TaobaoCircleOutlined,
} from "@ant-design/icons"
import MainLayout from "../../layouts/MainLayout"
import axiosInstance from "../../utils/axios.util"
import UserDisplay from "../../components/dashboard/UserDisplay"
import moment from "moment"
import { useAuth } from "../../context/AuthContext"
import usePrevious from "../../hooks/usePrevious"
import UserSelect, { fetchUserList } from "../../components/UserSelect"

const { Paragraph } = Typography
const { TextArea } = Input
const { Option } = Select
const { CheckableTag } = Tag

const tagsData = ["Chờ hoàn tiền", "Hoàn tiền"]
const markets = ["Tất cả", "Taobao", "1688"]

const onChange = (pagination, filters, sorter, extra) => {
  console.log("params", pagination, filters, sorter, extra)
}

const statusMap = {
  "Tất cả": "",
  "Chờ thanh toán": "waiting_payment",
  "Đã thanh toán": "payment_completed",
  "Đóng giao dịch": "staff_rejected",
  Hủy: "customer_canceled",
  "Hoàn tiền": "refunded",
  "Chờ hoàn tiền": "waiting_refund",
}
const marketMap = {
  "Tất cả": "",
  Taobao: "taobao",
  1688: "1688",
}
const reasons = ["Shop đóng giao dịch", "Hết hàng", "Thiếu hàng"]

export default function OrderManagement() {
  const [form] = Form.useForm()
  const [selectedUid, setSelectedUid] = useState(null)
  const [selectedStatuses, setSelectedStatuses] = useState(["Chờ hoàn tiền"])
  const [selectedMarkets, setSelectedMarkets] = useState(["Tất cả"])
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState("")
  const [selectedDay, setSelectedDay] = useState("")
  const [orderList, setOrderList] = useState([])
  const [accountsList, setAccountsList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({})
  const [pageSize, setPageSize] = useState(10)
  const [updateStatusModal, setUpdateStatusModal] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [selectedReason, setSelectedReason] = useState("")
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const prevPage = usePrevious(currentPage)
  const { userProfile } = useAuth()

  const columns = [
    {
      title: "Khách hàng",
      dataIndex: "customerId",
      key: "customerId",
      fixed: "left",

      render: (value, record) => (
        <Tooltip
          key={value}
          placement="top"
          title={
            <UserDisplay
              uid={value}
              userInfo={record.customer}
              displayRole={false}
              displayName={false}
            />
          }
        >
          <div style={{ width: "40px", height: "40px" }}>
            <UserDisplay
              userInfo={record.customer}
              uid={value}
              displayRole={false}
              displayName={false}
              displayEmail={false}
            />
          </div>
        </Tooltip>
      ),
      width: 100,
    },
    {
      title: "Nick mua hàng",
      dataIndex: "pickedAccount",
      key: "pickedAccount",
      fixed: "left",
      width: 150,
    },
    {
      title: "Mã đơn hàng",
      dataIndex: "orderNumber",
      key: "orderNumber",
      render: (value, order) => (
        <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
          {order.market === "taobao" ? (
            <TaobaoCircleOutlined
              style={{ color: "#FF4002", fontSize: "20px" }}
            />
          ) : (
            <AlibabaOutlined style={{ color: "#FF4002", fontSize: "20px" }} />
          )}{" "}
          <Paragraph copyable>{value}</Paragraph>
        </div>
      ),
      fixed: "left",
      width: 250,
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
      width: 100,
    },

    {
      title: "Số tiền (RMB)",
      dataIndex: "amount",
      key: "amountRmb",
      render: (value, order) => (
        <Paragraph copyable>
          {order.status === "waiting_refund" || order.status === "refunded"
            ? order.refundAmount
            : value}
        </Paragraph>
      ),
      width: 100,
    },

    {
      title: "Tỷ giá",
      dataIndex: "rate",
      key: "rate",
      width: 100,
    },
    {
      title: "Thành tiền (VNĐ)",
      dataIndex: "",
      key: "amountVnd",
      render: (value, order) => (
        <span>
          {order.status === "waiting_refund" || order.status === "refunded"
            ? new Intl.NumberFormat("en-us", {
                style: "currency",
                currency: "VND",
              }).format(order.refundAmount * order.rate)
            : new Intl.NumberFormat("en-us", {
                style: "currency",
                currency: "VND",
              }).format(order.amount * order.rate)}{" "}
        </span>
      ),
      width: 150,
    },
    {
      title: "Ngày hoàn",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value, order) => (
        <span>
          {order.status === "waiting_refund" || order.status === "refunded" ? (
            <span>
              {moment(order.refundDate).format("HH:mm:ss DD-MM-YYYY")}
            </span>
          ) : (
            <span>{moment(value).format("HH:mm:ss DD-MM-YYYY")}</span>
          )}
        </span>
      ),
      width: 150,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (value) => (
        <span>
          {value === "waiting_payment" ? (
            <Tag color="#f50">Chờ thanh toán</Tag>
          ) : value === "payment_completed" ? (
            <Tag color="#87d068">Đã thanh toán</Tag>
          ) : value === "staff_rejected" ? (
            <Tag color="#F54054">Đóng giao dịch</Tag>
          ) : value === "customer_canceled" ? (
            <Tag color="default">Hủy</Tag>
          ) : value === "refunded" ? (
            <Tag color="#108ee9">Đã hoàn tiền</Tag>
          ) : value === "waiting_refund" ? (
            <Tag color="#2db7f5">Chờ hoàn tiền</Tag>
          ) : value === "rejected_refund" ? (
            <Tag color="#F54054">Từ chối hoàn tiền</Tag>
          ) : (
            <Tag color="red">Đang cập nhật</Tag>
          )}
        </span>
      ),
      width: 150,
      fixed: "right",
    },

    {
      title: "Thao tác",
      dataIndex: "",
      key: "x",
      render: (value, order) => (
        <>
          {order.status === "staff_rejected" ? (
            <Tooltip title="Đặt lại đơn hàng" placement="top">
              <Button type="danger" ghost>
                <RedoOutlined />
              </Button>
            </Tooltip>
          ) : (
            <span style={{ display: "flex", gap: "10px" }}>
              {" "}
              <Tooltip title="Cập nhật đơn hàng" placement="top">
                <Button
                  type="primary"
                  ghost
                  onClick={() => showUpateStatusModal(order)}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
              <Tooltip title="Đặt lại đơn hàng" placement="top">
                <Button type="danger" ghost>
                  <RedoOutlined />
                </Button>
              </Tooltip>
            </span>
          )}
        </>
      ),
      fixed: "right",
      width: 120,
    },
  ]
  // Get Api Orders List
  const loadOrdersList = () => {
    axiosInstance
      .get("orders", {
        params: {
          status: statusMap[selectedStatuses[0]],
          market: marketMap[selectedMarkets[0]],
          page: currentPage,
          pickedAccount: selectedPaymentAccount,
          day: selectedDay,
          size: pageSize,
          customerId: selectedUid?.value,
        },
      })
      .then((res) => {
        setOrderList(res.data.items)
        setPagination(res.data.pagination)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        console.log("finally")
      })
  }

  // Get Api Accounts List
  useEffect(() => {
    axiosInstance
      .get("/accounts")
      .then((res) => {
        setAccountsList(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  //   Pagination
  useEffect(() => {
    if (prevPage === currentPage && currentPage !== 1) {
      setCurrentPage(1)
    } else {
      loadOrdersList()
    }
  }, [
    selectedStatuses,
    selectedMarkets,
    selectedPaymentAccount,
    selectedDay,
    currentPage,
    pageSize,
    selectedUid,
  ])

  const showUpateStatusModal = (order) => {
    setUpdateStatusModal(true)
    setSelectedOrder(order)
  }
  const handleUpdateOrderStatus = () => {
    if (selectedOrder.status === "refunded") {
      axiosInstance
        .post(`orders/${selectedOrder._id}/approve-refund-request`)
        .then((res) => {
          message.success("Đã hoàn tiền thành công đơn hàng")
          setUpdateStatusModal(false)
          loadOrdersList()
        })
        .catch((err) => {
          message.error("Cập nhật thất bại")
          setUpdateStatusModal(false)
        })
    }
    if (selectedOrder.status === "rejected_refund") {
      axiosInstance
        .post(`orders/${selectedOrder._id}/reject-refund-request`, {
          reason: selectedReason,
        })
        .then((res) => {
          message.success("Đã từ chối hoàn tiền đơn hàng")
          setUpdateStatusModal(false)
          loadOrdersList()
        })
        .catch((err) => {
          message.error("Cập nhật thất bại")
          setUpdateStatusModal(false)
        })
    }
  }

  const handleCancel = () => {
    setUpdateStatusModal(false)
  }

  //   Select and Change multi Orders Status
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      console.log(selectedRowKeys)
      setSelectedRowKeys(selectedRowKeys)
    },
  }
  const hasSelected = selectedRowKeys.length > 0
  const handleMultiChangeStatus = () => {
    Promise.all(
      selectedRowKeys.map((id) => {
        return axiosInstance.post(`orders/${id}/approve-refund-request`)
      })
    )
      .then(() => {
        message.success("Cập nhật thành công")
        setUpdateStatusModal(false)
        loadOrdersList()
      })
      .catch(() => {
        message.error("Cập nhật thất bại")
        setUpdateStatusModal(false)
      })
      .finally(() => {
        console.log("finally")
      })
  }
  return (
    <>
      <div>
        <Card style={{ marginTop: "20px" }}>
          <div className="status">
            <div style={{ marginBottom: "20px" }}>
              <span style={{ color: "#1890ff" }}>Trạng thái:</span>
              {tagsData.map((tag) => (
                <CheckableTag
                  key={tag}
                  checked={selectedStatuses.indexOf(tag) > -1}
                  onChange={() => setSelectedStatuses([tag])}
                  style={{ fontSize: "14px", marginLeft: "30px" }}
                >
                  {tag}
                </CheckableTag>
              ))}
            </div>
            <div style={{ marginBottom: "20px" }}>
              <span style={{ color: "#1890ff" }}>Trang TMĐT:</span>
              {markets.map((tag) => (
                <CheckableTag
                  key={tag}
                  checked={selectedMarkets.indexOf(tag) > -1}
                  onChange={() => setSelectedMarkets([tag])}
                  style={{ fontSize: "14px", marginLeft: "30px" }}
                >
                  {tag}
                </CheckableTag>
              ))}
            </div>
            <div
              style={{
                borderBottom: "2px dotted #f0f0f0",
                marginBottom: "20px",
              }}
            >
              <Form
                form={form}
                name="advanced_search"
                className="ant-advanced-search-form"
              >
                <Row gutter={24}>
                  <Col span={6}>
                    <Form.Item label="Khách hàng">
                      <UserSelect
                        showSearch
                        allowClear
                        value={selectedUid}
                        placeholder="Nhập email khách hàng"
                        fetchOptions={fetchUserList}
                        onChange={(newValue) => {
                          setSelectedUid(newValue)
                        }}
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Mã đơn hàng">
                      <Input placeholder="Nhập mã đơn hàng" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Tài khoản thanh toán">
                      <Select
                        showSearch
                        placeholder="Chọn tài khoản thanh toán"
                        onChange={(value) => {
                          setSelectedPaymentAccount(value)
                        }}
                      >
                        {accountsList?.map((account) => (
                          <Option key={account._id} value={account.username}>
                            {account.username}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Thời gian yêu cầu">
                      <DatePicker
                        style={{ width: "100%" }}
                        onChange={(date) => {
                          setSelectedDay(moment(date).format("YYYYMMDD"))
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <div>
              <Row>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={loadOrdersList}
                  >
                    Tìm kiếm
                  </Button>
                  <Button
                    style={{ margin: "0 8px" }}
                    onClick={() => {
                      form.resetFields()
                    }}
                  >
                    <SyncOutlined />
                    Làm mới bộ lọc
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Card>
      </div>
      <div>
        <Card style={{ marginTop: "20px" }}>
          <div
            style={{ margin: "20px 0", textAlign: "center", fontSize: "24px" }}
          >
            <h1>Danh sách Yêu cầu hoàn tiền</h1>
          </div>
          {hasSelected ? (
            <div>
              <div
                style={{
                  margin: "20px 0",
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                <span style={{ color: "#1890ff" }}>
                  Đã chọn {selectedRowKeys.length} đơn hàng
                </span>
                <Button type="primary" onClick={handleMultiChangeStatus}>
                  Hoàn tiền tất cả
                </Button>
              </div>
            </div>
          ) : (
            ""
          )}
          <div>
            <Table
              rowSelection={rowSelection}
              columns={columns}
              expandable={{
                expandedRowRender: (record) => (
                  <p
                    style={{
                      margin: 0,
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <UserDisplay
                      uid={record.customerId}
                      userInfo={record.customer}
                      displayAvatar={true}
                      displayRole={false}
                      displayEmail={false}
                    />
                    đã tạo lúc{" "}
                    {moment(record.createAt).format("HH:mm:ss DD-MM-YYYY")}
                  </p>
                ),
                rowExpandable: (record) =>
                  record.customerId !== "Not Expandable",
              }}
              dataSource={orderList}
              pagination={false}
              rowKey={(record) => record._id}
              scroll={{
                x: 1300,
              }}
            />

            <div className="mb-20">
              <Pagination
                total={pagination.totalItemCount}
                pageSize={pageSize}
                onShowSizeChange={(current, size) => {
                  setPageSize(size)
                }}
                showSizeChanger
                current={currentPage}
                onChange={(page) => {
                  setCurrentPage(page)
                }}
              />
            </div>
          </div>
        </Card>
      </div>
      {/* Modal update Order Status */}
      <Modal
        title="Cập nhật trạng thái đơn hàng"
        visible={updateStatusModal}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Hủy
          </Button>,
          <Button key="submit" type="primary" onClick={handleUpdateOrderStatus}>
            Xác nhận
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Nick mua hàng">
            <Input disabled value={selectedOrder?.pickedAccount} />
          </Form.Item>
          <Form.Item label="Mã đơn hàng">
            <Input disabled value={selectedOrder?.orderNumber} />
          </Form.Item>
          <Form.Item label="Số tệ">
            <Input disabled value={selectedOrder?.amount} />
          </Form.Item>
          <Form.Item label="Trạng thái">
            <Select
              placeholder="Chọn trạng thái"
              value={selectedOrder?.status}
              onChange={(value) =>
                setSelectedOrder({ ...selectedOrder, status: value })
              }
            >
              <Option value="waiting_refund">Chờ hoàn tiền</Option>
              <Option value="refunded">Hoàn tiền</Option>
              <Option value="rejected_refund">Từ chối hoàn tiền</Option>
            </Select>
          </Form.Item>
          {selectedOrder?.status === "staff_rejected" && (
            <Form.Item label="Lý do:">
              <Radio.Group value={selectedReason}>
                {reasons.map((reason) => (
                  <Row key={reason}>
                    <Col span={24}>
                      <Radio
                        value={reason}
                        onChange={(e) => {
                          setSelectedReason(reason)
                        }}
                      >
                        {reason}
                      </Radio>
                    </Col>
                  </Row>
                ))}
              </Radio.Group>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  )
}
