import { Card, Input, Row, Col, Button, message, Table } from "antd"
import moment from "moment"
import React, { useEffect, useState } from "react"
import UserDisplay from "../../components/dashboard/UserDisplay"
import MainLayout from "../../layouts/MainLayout"
import axiosInstance from "../../utils/axios.util"

export default function SystemRate() {
  const [systemRate, setSystemRate] = useState(0)
  const [updateSystemRate, setUpdateSystemRate] = useState("")
  const [systemRateList, setSystemRateList] = useState([])
  useEffect(() => {
    axiosInstance.get("/system-rates/today").then((res) => {
      setSystemRate(res.data.rate)
    })
  }, [])
  const handleUpdateSystemRate = () => {
    axiosInstance
      .post("/system-rates/today", {
        rate: updateSystemRate,
      })
      .then((res) => {
        setSystemRate(res.data.rate)
        setUpdateSystemRate("") // reset update system rate
        message.success("Cập nhật tỷ giá thành công")
      })
      .catch((err) => {
        message.error(err?.response?.data.message)
      })
  }
  useEffect(() => {
    axiosInstance.get("/system-rates").then((res) => {
      setSystemRateList(res.data)
    })
  }, [systemRate])

  const columns = [
    {
      title: "Ngày",
      dataIndex: "day",
      render: (value) => (
        <span>{moment(value, "YYYYMMDD").format("DD-MM-YYYY")}</span>
      ),
    },
    { title: "Tỷ giá", dataIndex: "rate", key: "rate" },
    {
      title: "Người cập nhật",
      dataIndex: "updaterId",
      render: (value) => (
        <UserDisplay displayAvatar={true} uid={value} displayRole={false} />
      ),
    },
  ]

  return (
    <>
      <Card title="CÀI ĐẶT TỶ GIÁ HỆ THỐNG">
        <span>
          Tỷ giá hệ thống hiện tại: <strong>{systemRate}</strong>
        </span>
      </Card>
      <div className="mb-20">
        <Card>
          <Row gutter={16}>
            <Col xs={24} md={3}>
              <span>Cập nhật tỷ giá:</span>
            </Col>
            <Col xs={24} md={4}>
              <Input
                value={updateSystemRate}
                onChange={(e) => setUpdateSystemRate(e.target.value)}
              />
            </Col>
            <Col xs={24} md={4}>
              <Button type="primary" onClick={handleUpdateSystemRate}>
                Cập nhật
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
      <div className="mb-20">
        <Card>
          <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
            Lịch sử thay đổi tỷ giá hệ thống
          </h1>
          <Table columns={columns} dataSource={systemRateList} rowKey="day" />
        </Card>
      </div>
    </>
  )
}
