import { Link } from "react-router-dom"
import {
  AlipayCircleOutlined,
  FileOutlined,
  PieChartOutlined,
  UserOutlined,
  SettingOutlined,
  DatabaseOutlined,
  TeamOutlined,
} from "@ant-design/icons"

const menuItems = [
  {
    label: <Link to={"/"}>Dashboard</Link>,
    key: "/",
    icon: <PieChartOutlined />,
    roles: ["customer", "staff", "admin"],
  },
  {
    label: "Thanh toán hộ",
    key: "/entrust-payment",
    icon: <AlipayCircleOutlined />,
    children: [
      {
        label: <Link to={"/entrust-payment/create"}>Tạo yêu cầu TTH</Link>,
        key: "/entrust-payment/create",
      },
      {
        label: <Link to={"/entrust-payment/list"}>Danh sách yêu cầu</Link>,
        key: "/entrust-payment/list",
      },
      {
        label: (
          <Link to={"/entrust-payment/refund-order-list"}>
            Đơn hàng hoàn tiền
          </Link>
        ),
        key: "/entrust-payment/refund-order-list",
      },
    ],
    roles: ["customer", "staff", "admin"],
  },
  {
    label: "Thông tin tài khoản",
    key: "/user-info",
    icon: <UserOutlined />,
    children: [
      {
        label: <Link to={"/user-info/user-profile"}>Cấu hình tài khoản</Link>,
        key: "/user-info/user-profile",
      },
      {
        label: (
          <Link to={"/user-info/user-transaction"}>Lịch sử giao dịch</Link>
        ),
        key: "/user-info/user-transaction",
      },
    ],
    roles: ["customer", "staff", "admin"],
  },
  {
    label: "Hướng dẫn",
    key: "guide",
    icon: <FileOutlined />,
    roles: ["customer"],
  },
  {
    label: "Quản lý đơn hàng",
    key: "/order-management",
    icon: <DatabaseOutlined />,
    children: [
      {
        label: (
          <Link to={"/order-management/order-list"}>Danh sách đơn hàng</Link>
        ),
        key: "/order-management/order-list",
      },
      {
        label: (
          <Link to={"/order-management/refund-list"}>Xử lý khiếu nại</Link>
        ),
        key: "/order-management/refund-list",
      },
    ],
    roles: ["staff", "admin"],
  },
  {
    label: "Cài đặt",
    key: "/manager",
    icon: <SettingOutlined />,
    children: [
      {
        label: <Link to={"/manager/system-rate"}>Tỷ giá hệ thống</Link>,
        key: "/manager/system-rate",
      },
      {
        label: <Link to={"/manager/account-list"}>Tài khoản mua hàng</Link>,
        key: "/manager/account-list",
      },
    ],
    roles: ["staff", "admin"],
  },
  {
    label: <Link to={"/manager/user-list"}>Danh sách người dùng</Link>,
    key: "/manager/user-list",
    icon: <TeamOutlined />,
    roles: ["staff", "admin"],
  },
]

export default menuItems
