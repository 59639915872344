import { Avatar } from "antd"
import React, { useEffect, useState } from "react"
import axiosInstance from "../../utils/axios.util"

export default function UserDisplay({
  uid,
  displayAvatar = true,
  displayRole = true,
  displayName = true,
  userInfo = null,
  displayEmail = true,
}) {
  const [user, setUser] = useState(userInfo)
  useEffect(() => {
    if (uid && !userInfo) {
      axiosInstance.get(`/users/${uid}`).then((res) => {
        setUser(res.data)
      })
    }
  }, [uid, userInfo])
  if (!user) return null
  return (
    <span className="auth">
      {" "}
      {displayAvatar && <Avatar size={40} src={user.photoURL} />}
      {displayName && user.displayName}
      {displayRole && <span>{user.role}</span>}
      {displayEmail && <span>{user.email}</span>}
    </span>
  )
}
