import "antd/dist/antd.css"
import CustomerHome from "./pages/Home/CustomerHome"
import { Route, Routes, Outlet } from "react-router-dom"
import LoginPage from "./pages/Auth/LoginPage"
import { AuthContextProvider } from "./context/AuthContext"
import CreateEntrustRequest from "./pages/EntrustRequest/CreateEntrustRequest"
import ListEntrustRequests from "./pages/EntrustRequest/ListEntrustRequests"
import CustomerInfo from "./pages/User/CustomerInfo"
import SystemRate from "./pages/Adminstration/SystemRate"
import UsersList from "./pages/Adminstration/UsersList"
import PageForGuest from "./pages/User/PageForGuest"
import AccountList from "./pages/Adminstration/AccountList"
import OrderManagement from "./pages/Adminstration/OrderManagement"
import RefundManagement from "./pages/Adminstration/RefundManagement"
import UserTransaction from "./pages/User/UserTransaction"
import MainLayout from "./layouts/MainLayout"
import UserRefundOrderList from "./pages/User/UserRefundOrderList"

function App() {
  return (
    <div>
      <AuthContextProvider>
        <Routes>
          <Route path={"/login"} element={<LoginPage />} />
          <Route path={"/page-for-guest"} element={<PageForGuest />} />
          <Route
            path="/"
            element={
              <MainLayout>
                <Outlet />
              </MainLayout>
            }
          >
            <Route path={"/"} element={<CustomerHome />} />
            <Route path={"/entrust-payment"}>
              <Route path={"create"} element={<CreateEntrustRequest />} />
              <Route path={"list"} element={<ListEntrustRequests />} />
              <Route
                path={"refund-order-list"}
                element={<UserRefundOrderList />}
              />
            </Route>
            <Route path={"/user-info"}>
              <Route path={"user-profile"} element={<CustomerInfo />} />
              <Route path={"user-transaction"} element={<UserTransaction />} />
            </Route>
            <Route path={"/manager"}>
              <Route path={"system-rate"} element={<SystemRate />} />
              <Route path={"order-list"} element={<OrderManagement />} />
              <Route path={"refund-list"} element={<RefundManagement />} />
              <Route path={"user-list"} element={<UsersList />} />
              <Route path={"account-list"} element={<AccountList />} />
            </Route>
            <Route path={"/order-management"}>
              <Route path={"order-list"} element={<OrderManagement />} />
              <Route path={"refund-list"} element={<RefundManagement />} />
            </Route>
          </Route>
        </Routes>
      </AuthContextProvider>
    </div>
  )
}

export default App
