import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Input,
  Row,
  Switch,
  Tabs,
  Upload,
} from "antd"
import React, { useEffect, useState } from "react"
import { UploadOutlined } from "@ant-design/icons"
import axiosInstance from "../../utils/axios.util"

const { TabPane } = Tabs
const props = {
  action: "//jsonplaceholder.typicode.com/posts/",
  listType: "picture",
  previewFile(file) {
    console.log("Your upload file:", file)
    // Your process logic. Here we just mock to the same file
    return fetch("https://next.json-generator.com/api/json/get/4ytyBoLK8", {
      method: "POST",
      body: file,
    })
      .then((res) => res.json())
      .then(({ thumbnail }) => thumbnail)
  },
}
export default function CustomerInfo() {
  const [currentUser, setCurrentUser] = useState({})

  useEffect(() => {
    axiosInstance.get("/user/me").then((res) => {
      setCurrentUser(res.data)
    })
  }, [])
  return (
    <>
      <div className="container">
        <Row>
          <Col xs={24}>
            <Card>
              <Tabs tabPosition="left">
                <TabPane tab="Thông tin tài khoản" key="1">
                  <Row gutter={8} align="middle">
                    <Col>
                      <Avatar size={64} src={currentUser.photoURL} />
                    </Col>
                    <Col>
                      <Upload {...props}>
                        <Button icon={<UploadOutlined />}>Thay Avata</Button>
                      </Upload>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={16}>
                    <Col xs={24} md={12}>
                      <div className="form-group">
                        <Input
                          type="text"
                          className="form-control"
                          id="displayName"
                          placeholder="Nhập tên đăng nhập"
                          value={currentUser.displayName}
                        />
                      </div>
                    </Col>
                    <Col xs={24} md={12}>
                      <div className="form-group">
                        <Input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder="Nhập email"
                          value={currentUser.email}
                        />
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Chính sách áp dụng" key="2">
                  <Row gutter={16}>
                    <Col xs={24} md={12}>
                      <h3 className="mb-20">Tài khoản mua hàng</h3>
                      <Row gutter={(16, 8)}>
                        {currentUser?.accounts?.map((account, index) => (
                          <Col key={index} xs={24} md={12}>
                            <Card>
                              <span>{account}</span>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                    <Col xs={24} md={12}>
                      <h3 className="mb-20">Tỷ giá</h3>
                      <Input value={currentUser.exchangeRate} disabled />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Cài đặt thông báo" key="3">
                  <h3>Cài đặt thông báo</h3>
                  <Row className="mb-10">
                    <Switch defaultChecked style={{ marginRight: "16px" }} />
                    Thông báo số dư
                  </Row>
                  <Row className="mb-10">
                    <Switch defaultChecked style={{ marginRight: "16px" }} />
                    Thông báo trạng thái đơn hàng
                  </Row>
                  <Row className="mb-10">
                    <Switch defaultChecked style={{ marginRight: "16px" }} />
                    Thông báo hoàn tiền
                  </Row>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}
