import useSWR from "swr"
import axiosInstance from "../utils/axios.util"
import ActivitiesTimeline from "./ActivitiesTimeline"

export default function OrderActivities({ orderId, maxActivities = 20 }) {
  const { data: activities } = useSWR("/activities?orderId=" + orderId, (url) =>
    axiosInstance
      .get(url, {
        params: {
          entityType: "order",
          entityId: orderId,
          size: maxActivities,
        },
      })
      .then((res) => res.data.items)
  )

  return <ActivitiesTimeline activities={activities || []} />
}
