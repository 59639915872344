import axios from 'axios'
import config from '../config'
import firebaseApp from '../firebase'
import { getAuth } from '@firebase/auth'

const auth = getAuth(firebaseApp)

const axiosInstance = axios.create({
  baseURL: config.baseURL,
})

axiosInstance.interceptors.request.use(async (config) => {
  const idToken = await auth.currentUser?.getIdToken()
  config.headers = idToken
    ? {
        Authorization: `Bearer ${idToken}`,
        ...config.headers
      }
    : config.headers

  return config
})

export default axiosInstance
