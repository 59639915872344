import React, { useState, useEffect } from "react"
import { useAuth } from "../../context/AuthContext"
import { Navigate } from "react-router-dom"
import axiosInstance from "../../utils/axios.util"

export default function PrivateRoute({ children }) {
  const { user } = useAuth()
  const [userLogin, setUserLogin] = useState({})
  useEffect(() => {
    if (user) {
      axiosInstance.get("/user/me").then((res) => {
        setUserLogin(res.data)
      })
    }
  }, [])
  if (!user) {
    return <Navigate to="/login" />
  }

  if (userLogin.role === "guest") {
    return <Navigate to="/page-for-guest" />
  }
  return <>{children}</>
}
