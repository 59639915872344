import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  Col,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
} from "antd"
import {
  DeleteFilled,
  DiffOutlined,
  PlusCircleOutlined,
  SendOutlined,
} from "@ant-design/icons"
import axiosInstance from "../../utils/axios.util"
import moment from "moment"
import { useAuth } from "../../context/AuthContext"

const { Title } = Typography
const { TextArea } = Input
const { Option } = Select

export default function CreateEntrustRequest() {
  const [accounts, setAccounts] = useState([])
  const [marketSupports, setMarketSupports] = useState([])
  const [selectedAccountUsername, setSelectedAccountUsername] = useState("")
  const [selectedMarketSupportUsername, setSelectedMarketSupportUsername] =
    useState("")
  const [userRate, setUserRate] = useState(0)
  const { userProfile } = useAuth()
  useEffect(() => {
    axiosInstance.get(`/user/rate`).then((res) => {
      setUserRate(res.data.rate)
    })
  }, [])
  useEffect(() => {
    axiosInstance.get("/accounts").then((res) => {
      setAccounts(res.data)
    })
  }, [])
  useEffect(() => {
    const selectedAccount = accounts.find(
      (account) => account.username === selectedAccountUsername
    )
    setMarketSupports(selectedAccount?.marketSupports || [])
  }, [selectedAccountUsername])

  const [commands, setCommands] = useState([
    {
      amount: 0,
      orderNumber: "",
      note: "",
    },
  ])
  const [paymentMethod, setPaymentMethod] = useState("entrust_payment")
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [batchOrder, setBatchOrder] = useState("")

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    const batchCommands = convertToCommands(batchOrder)
    setCommands(commands.concat(batchCommands))
    setBatchOrder("")
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const totalAmountRMB = commands.reduce(
    (accumulator, current) => accumulator + current.amount,
    0
  )
  const totalAmountVND = totalAmountRMB * userRate

  const handleAddEntrustRequest = () => {
    setCommands(commands.concat({ amount: 0, note: "" }))
  }

  const handleRemoveEntrusRequest = (index) => {
    commands.splice(index, 1)
    setCommands([...commands])
  }

  const handlePropertyChange = (index, updated, property) => {
    commands[index] = {
      ...commands[index],
      [property]: updated,
    }
    setCommands([...commands])
  }

  const isShowRemoveButton = commands.length > 1

  const orderRequests = {
    pickedAccount: selectedAccountUsername,
    market: selectedMarketSupportUsername,
    commands: commands,
  }
  function success() {
    axiosInstance.post("/user/orders/batch", orderRequests).then((res) => {
      Modal.success({
        content: "Yêu cầu thanh toán hộ đã được gửi thành công",
      })
      setCommands([{ amount: 0, note: "" }])
      setSelectedAccountUsername("")
      setSelectedMarketSupportUsername("")
    })
  }

  const handleChange = (value) => {
    setPaymentMethod(value)
    console.log(`selected ${value}`)
  }
  const convertToCommands = (raw) =>
    raw
      .split(/\n/)
      .filter((rawRow) => !!rawRow?.trim())
      .map((rawRow) => rawRow.split(/\s+|,/))
      .map(([orderNumber, amount]) => ({
        orderNumber,
        amount: parseFloat(amount),
      }))

  return (
    <>
      <div className="container">
        <Card>
          <div style={{ textAlign: "center" }}>
            <Title level={3}>GỬI YÊU CẦU THANH TOÁN HỘ</Title>
          </div>
          <div style={{ paddingTop: "32px" }}>
            <Row gutter={[0, 8]}>
              <Col xs={24} md={6}>
                Chọn hình thức thanh toán
              </Col>
              <Col xs={24} md={8}>
                <Select
                  style={{ width: "100%" }}
                  value={paymentMethod}
                  onChange={handleChange}
                >
                  <Option value="entrust_payment">Thanh toán hộ</Option>
                  <Option value="alipay">Alipay / Ngân hàng</Option>
                </Select>
              </Col>
            </Row>
            {paymentMethod === "entrust_payment" && (
              <>
                <Row className="mb-20">
                  <Col xs={24} md={6}>
                    Account mua hàng
                  </Col>
                  <Col xs={24} md={8}>
                    <Select
                      style={{ width: "100%" }}
                      value={selectedAccountUsername}
                      onChange={(value) => setSelectedAccountUsername(value)}
                    >
                      {userProfile?.accounts?.map((account) => (
                        <Option key={account} value={account}>
                          {account}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                <Row className="mb-20">
                  <Col xs={24} md={6}>
                    Trang TMĐT
                  </Col>
                  <Col xs={24} md={8}>
                    <Select
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        setSelectedMarketSupportUsername(value)
                      }
                      value={selectedMarketSupportUsername}
                    >
                      {marketSupports.map((marketSupport) => (
                        <Option key={marketSupport} value={marketSupport}>
                          {marketSupport}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </>
            )}

            <Row className="mb-20">
              <Col xs={24} md={6}>
                Nội dung thanh toán
              </Col>
              <Col flex="auto">
                {commands.map((item, index) => (
                  <Row key={index} gutter={[8, 8]} style={{ marginBottom: 16 }}>
                    <Col xs={24} md={12}>
                      <Input
                        value={item.orderNumber}
                        onChange={(e) =>
                          handlePropertyChange(
                            index,
                            e.target.value,
                            "orderNumber"
                          )
                        }
                        placeholder={
                          paymentMethod === "entrust_payment"
                            ? "Mã đơn hàng"
                            : "Số tài khoản"
                        }
                      />
                    </Col>
                    <Col flex="auto">
                      <InputNumber
                        style={{ width: "100%" }}
                        onChange={(value) =>
                          handlePropertyChange(index, value, "amount")
                        }
                        value={item.amount}
                        placeholder="Số tệ"
                      />
                    </Col>
                    <Col flex="auto">
                      <Input
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handlePropertyChange(index, e.target.value, "note")
                        }
                        value={item.note}
                        placeholder="Ghi chú"
                      />
                    </Col>
                    {isShowRemoveButton && (
                      <Col span={1}>
                        <Button
                          type="danger"
                          shape="circle"
                          icon={<DeleteFilled />}
                          onClick={handleRemoveEntrusRequest.bind(this, index)}
                        />
                      </Col>
                    )}
                  </Row>
                ))}
              </Col>
            </Row>

            <Row className="mb-20">
              <Col span={6}></Col>
              <Col span={18}>
                <Button
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  onClick={handleAddEntrustRequest}
                  style={{ marginRight: "8px" }}
                >
                  Thêm yêu cầu
                </Button>
                <Button
                  type="primary"
                  icon={<DiffOutlined />}
                  onClick={showModal}
                  ghost
                >
                  Thêm hàng loạt
                </Button>
              </Col>
            </Row>
            <Modal
              title="Thêm hàng loạt đơn hàng"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              okText="Thêm"
              cancelText="Hủy"
            >
              <TextArea
                showCount
                maxLength={5000}
                rows={10}
                value={batchOrder}
                onChange={(e) => setBatchOrder(e.target.value)}
                placeholder={
                  "Mỗi đơn hàng là một dòng.\n" +
                  "Cú pháp: [mã đơn hàng],[số tệ](Có thể copy từ Excel). VD: \n" +
                  "2700148358824632539,1000\n" +
                  "2692554336676632539  600 \n" +
                  "2692554336676632539,44.8"
                }
              />
            </Modal>
            <Row style={{ marginTop: "20px" }}>
              <Col span={6}>Tổng tiền tệ</Col>
              <Col span={18}>
                <strong>
                  {new Intl.NumberFormat("zh-cn", {
                    style: "currency",
                    currency: "RMB",
                  }).format(totalAmountRMB)}
                </strong>
              </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Col span={6}>Tổng tiền VNĐ</Col>
              <Col span={18}>
                <strong>
                  {new Intl.NumberFormat("vi-vn", {
                    style: "currency",
                    currency: "VND",
                  }).format(totalAmountVND)}{" "}
                </strong>
                &nbsp;- Tỷ giá <strong>{userRate} (VNĐ/RMB).</strong> Cập nhật{" "}
                {moment().format("DD-MM-YYYY")}
              </Col>
            </Row>

            <div style={{ textAlign: "right" }}>
              <Button type="primary" onClick={success} icon={<SendOutlined />}>
                Gửi Yêu Cầu
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}
