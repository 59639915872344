import React, { useEffect, useState } from "react"
import {
  Alert,
  Card,
  Col,
  Row,
  Button,
  Modal,
  Statistic,
  Table,
  Tag,
} from "antd"
import { TransactionOutlined } from "@ant-design/icons"
import axiosInstance from "../../utils/axios.util"
import { Link } from "react-router-dom"
import UserDisplay from "./UserDisplay"
import moment from "moment"

import { TaobaoCircleOutlined, AlibabaOutlined } from "@ant-design/icons"

export default function CustomerDashboard() {
  const [currentUser, setCurrentUser] = useState({})
  useEffect(() => {
    axiosInstance.get("/user/me").then((res) => {
      setCurrentUser(res.data)
    })
  }, [])
  const [orderList, setOrderList] = useState([])
  const columns = [
    {
      title: "Nick mua hàng",
      dataIndex: "pickedAccount",
      key: "pickedAccount",
    },
    {
      title: "Mã đơn hàng",
      dataIndex: "orderNumber",
      key: "orderNumber",
      render: (value, order) => (
        <span>
          {order.market === "taobao" ? (
            <TaobaoCircleOutlined
              style={{ color: "#FF4002", fontSize: "20px" }}
            />
          ) : (
            <AlibabaOutlined style={{ color: "#FF4002", fontSize: "20px" }} />
          )}{" "}
          {value}
        </span>
      ),
    },
    {
      title: "Số tiền (RMB)",
      dataIndex: "amount",
      key: "amountRmb",
    },
    {
      title: "Tỷ giá",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "Thành tiền (VNĐ)",
      dataIndex: "",
      key: "amountVnd",
      render: (value, order) => (
        <span>
          {new Intl.NumberFormat("vi-vn", {
            style: "currency",
            currency: "VND",
          }).format(order.amount * order.rate)}{" "}
        </span>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (value) => (
        <span>
          {value === "waiting_payment" ? (
            <Tag color="#f50">Chờ thanh toán</Tag>
          ) : value === "payment_completed" ? (
            <Tag color="#87d068">Đã thanh toán</Tag>
          ) : value === "rejected" ? (
            <Tag color="#F54054">Đóng giao dịch</Tag>
          ) : value === "customer_cancelled" ? (
            <Tag color="#f50">Hủy</Tag>
          ) : value === "refunded" ? (
            <Tag color="#108ee9">Đã hoàn tiền</Tag>
          ) : value === "waiting_refund" ? (
            <Tag color="#2db7f5">Chờ hoàn tiền</Tag>
          ) : value === "rejected_refund" ? (
            <Tag color="#F54054">Từ chối hoàn tiền</Tag>
          ) : (
            <Tag color="red">Đang cập nhật</Tag>
          )}
        </span>
      ),
    },
    {
      title: "Khởi tạo",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => (
        <span>{moment(value).format("HH:mm:ss DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (value, order) => (
        <span>
          {order.status === "waiting_payment" ? (
            <Button type="primary" ghost>
              Hủy
            </Button>
          ) : order.status === "payment_completed" ? (
            <Button type="danger" ghost>
              Hoàn tiền
            </Button>
          ) : order.status === "rejected" ? (
            <div />
          ) : order.status === "waiting_refund" ? (
            <Button type="primary">Huỷ hoàn tiền</Button>
          ) : order.status === "rejected_refund" ? (
            <Button type="danger" ghost>
              Hoàn tiền
            </Button>
          ) : order.status === "customer_cancelled" ? (
            <Button type="default">Xóa</Button>
          ) : order.status === "refunded" ? (
            <Button type="default">Xóa</Button>
          ) : (
            <Button type="default">Xóa</Button>
          )}
        </span>
      ),
    },
  ]
  useEffect(() => {
    axiosInstance
      .get("user/orders")
      .then((res) => {
        setOrderList(res.data.items)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        console.log("finally")
      })
  }, [])
  return (
    <div>
      <div>
        <Row style={{ marginTop: "20px" }} gutter={[16, 24]}>
          <Col md={24} lg={10}>
            <h1 className="layoutTitle">Số dư tài khoản</h1>
            <Card className="cardLayout">
              <Row>
                <Col span={8}>
                  <div>
                    <Statistic
                      title="Số dư khả dụng(VNĐ)"
                      value={currentUser.balance}
                      precision={0}
                    />
                  </div>
                </Col>
                <Col span={8} offset={8} style={{ textAlign: "right" }}>
                  <Link to={"/user-info/user-transaction"}>
                    <Button type="primary">
                      <TransactionOutlined />
                      Lịch sử giao dịch
                    </Button>
                  </Link>
                </Col>
              </Row>
              <Row>
                <p style={{ marginTop: "36px" }}>ĐƠN ĐANG CHỜ THANH TOÁN</p>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Statistic title="Số đơn chờ TT" value={12} />
                </Col>
                <Col span={8}>
                  <Statistic title="Số tệ chờ TT" value={2000} />
                </Col>
                <Col span={8}>
                  <Statistic title="Quy đổi ra VNĐ" value={7000000} />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} lg={14}>
            <h1 className="layoutTitle">Giao dịch hôm nay</h1>
            <Row gutter={[16, 24]}>
              <Col md={24} lg={12}>
                <Card>
                  <Statistic
                    title="TỔNG RMB HÔM NAY"
                    value={112893}
                    precision={0}
                  />
                </Card>
              </Col>
              <Col md={24} lg={12}>
                <Card className="cardLayout card-hover">
                  <Statistic
                    title="TỔNG VNĐ HÔM NAY"
                    value={112893}
                    precision={0}
                  />
                </Card>
              </Col>
              <Col md={24} lg={12}>
                <Card className="cardLayout card-hover">
                  <Statistic
                    title="ĐÃ NẠP TIỀN HÔM NAY (VNĐ)"
                    value={112893}
                    precision={0}
                  />
                </Card>
              </Col>
              <Col md={24} lg={12}>
                <Card className="cardLayout card-hover">
                  <Statistic
                    title="HOÀN TIỀN HÔM NAY (VNĐ)"
                    value={112893}
                    precision={0}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div>
        <Card className="cardLayout" style={{ marginTop: "20px" }}>
          <div>
            <Row>
              <Col span={24} style={{ textAlign: "right" }}>
                <Button type="primary" ghost htmlType="submit">
                  <TransactionOutlined /> Tạo yêu cầu thanh toán
                </Button>
                <Button style={{ margin: "0 8px", color: "green" }}>
                  Chi tiết
                </Button>
              </Col>
            </Row>
          </div>
          <div
            style={{ margin: "20px 0", textAlign: "center", fontSize: "24px" }}
          >
            <h1>Danh sách Yêu cầu thanh toán</h1>
          </div>
          <div>
            <Table
              columns={columns}
              expandable={{
                expandedRowRender: (record) => (
                  <p
                    style={{
                      margin: 0,
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <UserDisplay
                      uid={record.customerId}
                      displayAvatar={true}
                      displayRole={false}
                    />
                    đã tạo lúc{" "}
                    {moment(record.createAt).format("HH:mm:ss DD-MM-YYYY")}
                  </p>
                ),
                rowExpandable: (record) =>
                  record.customerId !== "Not Expandable",
              }}
              dataSource={orderList}
              rowKey={(record) => record.id}
            />
          </div>
        </Card>
      </div>
    </div>
  )
}
