import { Timeline } from "antd"
import moment from "moment"

export default function ActivitiesTimeline({ activities = [] }) {
  return (
    <Timeline mode={"left"} reverse={true}>
      {activities.map((activity) => (
        <Timeline.Item key={activity._id}>
          [{moment(activity.createdAt).format("HH:mm DD/MM/YYYY")}]{" "}
          {activity.description}
        </Timeline.Item>
      ))}
    </Timeline>
  )
}
