import React from "react"
import CustomerDashboard from "../../components/dashboard/CustomerDashboard"
import PrivateRoute from "../../components/dashboard/PrivateRoute"
import MainLayout from "../../layouts/MainLayout"

export default function CustomerHome() {
  return (
    <>
      <CustomerDashboard />
    </>
  )
}
