import React, { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd"
import {
  AlibabaOutlined,
  RedoOutlined,
  SyncOutlined,
  TaobaoCircleOutlined,
} from "@ant-design/icons"
import axiosInstance from "../../utils/axios.util"
import UserDisplay from "../../components/dashboard/UserDisplay"
import moment from "moment"
import { useAuth } from "../../context/AuthContext"
import usePrevious from "../../hooks/usePrevious"

const { Paragraph } = Typography
const { TextArea } = Input
const { Option } = Select
const { CheckableTag } = Tag

const tagsData = ["Chờ hoàn tiền", "Đã Hoàn tiền"]
const markets = ["Tất cả", "Taobao", "1688"]

const onChange = (pagination, filters, sorter, extra) => {
  console.log("params", pagination, filters, sorter, extra)
}

const statusMap = {
  "Chờ hoàn tiền": "waiting_refund",
  "Đã Hoàn tiền": "refunded",
}
const marketMap = {
  "Tất cả": "",
  Taobao: "taobao",
  1688: "1688",
}

export default function UserRefundOrderList() {
  const [form] = Form.useForm()
  const [selectedStatuses, setSelectedStatuses] = useState(["Chờ hoàn tiền"])
  const [selectedMarkets, setSelectedMarkets] = useState(["Tất cả"])
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState("")
  const [selectedDay, setSelectedDay] = useState("")
  const [orderList, setOrderList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [modalRefundRequest, setModalRefundRequest] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState({})
  const [refundAmount, setRefundAmount] = useState(null)
  const [refundDate, setRefundDate] = useState("")

  const prevPage = usePrevious(currentPage)

  const [pagination, setPagination] = useState({})
  const { userProfile } = useAuth()

  const confirm = (id) => {
    axiosInstance
      .post(`/user/orders/${id}/cancel`)
      .then((res) => {
        message.success("Hủy giao dịch thành công")
        loadOrdersList()
      })
      .catch((err) => {
        message.error("Hủy giao dịch thất bại")
      })
  }
  const cancelRefund = (id) => {
    axiosInstance
      .post(`/user/orders/${id}/cancel-refund-request`)
      .then((res) => {
        message.success("Hủy yêu cầu hoàn tiền thành công")
        loadOrdersList()
      })
      .catch((err) => {
        message.error("Hủy yêu cầu hoàn tiền thất bại")
      })
  }
  const columns = [
    {
      title: "Nick mua hàng",
      dataIndex: "pickedAccount",
      key: "pickedAccount",
      fixed: "left",
      width: "12%",
    },
    {
      title: "Mã đơn hàng",
      dataIndex: "orderNumber",
      key: "orderNumber",
      render: (value, order) => (
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "flex-start",
          }}
        >
          <span>
            {order.market === "taobao" ? (
              <TaobaoCircleOutlined
                style={{ color: "#FF4002", fontSize: "20px" }}
              />
            ) : (
              <AlibabaOutlined style={{ color: "#FF4002", fontSize: "20px" }} />
            )}
          </span>
          <Paragraph copyable>{value}</Paragraph>
        </div>
      ),
      width: "15%",
      fixed: "left",
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "Số tiền (RMB)",
      dataIndex: "amount",
      key: "amountRmb",
      render: (value, order) => (
        <Paragraph copyable>
          {order.status === "waiting_refund" || order.status === "refunded"
            ? order.refundAmount
            : value}
        </Paragraph>
      ),
    },
    {
      title: "Tỷ giá",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "Thành tiền (VNĐ)",
      dataIndex: "",
      key: "amountVnd",
      render: (value, order) => (
        <span>
          {order.status === "waiting_refund" || order.status === "refunded"
            ? new Intl.NumberFormat("en-us", {
                style: "currency",
                currency: "VND",
              }).format(order.refundAmount * order.rate)
            : new Intl.NumberFormat("en-us", {
                style: "currency",
                currency: "VND",
              }).format(order.amount * order.rate)}{" "}
        </span>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (value) => (
        <span>
          {value === "waiting_payment" ? (
            <Tag color="#f50">Chờ thanh toán</Tag>
          ) : value === "payment_completed" ? (
            <Tag color="#87d068">Đã thanh toán</Tag>
          ) : value === "staff_rejected" ? (
            <Tag color="#F54054">Đóng giao dịch</Tag>
          ) : value === "customer_canceled" ? (
            <Tag color="#f50">Hủy</Tag>
          ) : value === "refunded" ? (
            <Tag color="#108ee9">Đã hoàn tiền</Tag>
          ) : value === "waiting_refund" ? (
            <Tag color="#2db7f5">Chờ hoàn tiền</Tag>
          ) : value === "rejected_refund" ? (
            <Tag color="#F54054">Từ chối hoàn tiền</Tag>
          ) : (
            <Tag color="red">Đang cập nhật</Tag>
          )}
        </span>
      ),
    },

    {
      title: "Ngày hoàn",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value, order) => (
        <span>
          {order.status === "waiting_refund" || order.status === "refunded" ? (
            <span>
              {moment(order.refundDate).format("HH:mm:ss DD-MM-YYYY")}
            </span>
          ) : (
            <span>{moment(value).format("HH:mm:ss DD-MM-YYYY")}</span>
          )}
        </span>
      ),
    },
    {
      title: "Thao tác",
      dataIndex: "",
      key: "x",
      render: (value, order) => (
        <span>
          {order.status === "waiting_payment" ? (
            <Popconfirm
              title="Bạn có chắc chắn muốn hủy đơn hàng này?"
              placement="top"
              okText="Đồng ý"
              cancelText="Hủy"
              onConfirm={() => confirm(order._id)}
            >
              <Button type="primary" ghost>
                Hủy
              </Button>
            </Popconfirm>
          ) : order.status === "payment_completed" ? (
            <Button
              type="primary"
              onClick={() => showModalRefundRequest(order)}
            >
              Y/C Hoàn tiền
            </Button>
          ) : order.status === "staff_rejected" ? (
            <Tooltip placement="top" title="Đặt lại đơn hàng">
              <Button type="danger" ghost>
                <RedoOutlined />
              </Button>
            </Tooltip>
          ) : order.status === "waiting_refund" ? (
            <Popconfirm
              placement="top"
              title="Bạn có chắc hủy hoàn tiền đơn hàng này"
              okText="Đồng ý"
              cancelText="Hủy"
              onConfirm={() => cancelRefund(order._id)}
            >
              <Button type="default">Huỷ hoàn tiền</Button>
            </Popconfirm>
          ) : order.status === "rejected_refund" ? (
            <Button type="danger" ghost>
              Hoàn tiền
            </Button>
          ) : order.status === "customer_canceled" ? (
            <Tooltip placement="top" title="Đặt lại đơn hàng">
              <Button type="danger" ghost>
                <RedoOutlined />
              </Button>
            </Tooltip>
          ) : order.status === "refunded" ? (
            <Tooltip placement="top" title="Đặt lại đơn hàng">
              <Button type="danger" ghost>
                <RedoOutlined />
              </Button>
            </Tooltip>
          ) : (
            <Button type="danger" ghost>
              <RedoOutlined />
            </Button>
          )}
        </span>
      ),
      fixed: "right",
    },
  ]

  const loadOrdersList = () => {
    axiosInstance
      .get("user/orders", {
        params: {
          status: statusMap[selectedStatuses[0]],
          market: marketMap[selectedMarkets[0]],
          page: currentPage,
          pickedAccount: selectedPaymentAccount,
          day: selectedDay,
          size: pageSize,
        },
      })
      .then((res) => {
        setOrderList(res.data.items)
        setPagination(res.data.pagination)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        console.log("finally")
      })
  }
  useEffect(() => {
    if (prevPage === currentPage && currentPage !== 1) {
      setCurrentPage(1)
    } else {
      loadOrdersList()
    }
  }, [
    currentPage,
    selectedStatuses,
    selectedMarkets,
    selectedPaymentAccount,
    selectedDay,
    pageSize,
  ])

  const handleClearFilters = () => {
    setSelectedStatuses([])
    setSelectedMarkets([])
    setSelectedPaymentAccount("")
    setSelectedDay("")
  }

  const showModalRefundRequest = (order) => {
    setModalRefundRequest(true)
    setSelectedOrder(order)
    setRefundAmount(order.amount)
  }

  const handleSendRefundRequest = () => {
    axiosInstance
      .post(`user/orders/${selectedOrder._id}/request-refund`, {
        refundAmount: refundAmount,
        refundDate: refundDate,
      })
      .then((res) => {
        message.success("Yêu cầu hoàn tiền thành công")
        setModalRefundRequest(false)
        loadOrdersList()
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        console.log("finally")
      })
  }

  return (
    <>
      <div className="alert">
        <Alert
          message="Thông báo"
          description={
            <span>
              Hôm nay bạn đã gửi {orderList.length} đơn hàng TTH cho
              Giaodichchina.vn. Cảm ơn bạn đã tin tưởng sử dụng dịch vụ!
            </span>
          }
          type="success"
          showIcon
          closable
        />
      </div>
      <div>
        <Card style={{ marginTop: "20px" }}>
          <div className="status">
            <div style={{ marginBottom: "20px" }}>
              <span style={{ color: "#1890ff" }}>Trạng thái:</span>
              {tagsData.map((tag) => (
                <CheckableTag
                  key={tag}
                  checked={selectedStatuses.indexOf(tag) > -1}
                  onChange={() => setSelectedStatuses([tag])}
                  style={{ fontSize: "14px", marginLeft: "30px" }}
                >
                  {tag}
                </CheckableTag>
              ))}
            </div>
            <div style={{ marginBottom: "20px" }}>
              <span style={{ color: "#1890ff" }}>Trang TMĐT:</span>
              {markets.map((tag) => (
                <CheckableTag
                  key={tag}
                  checked={selectedMarkets.indexOf(tag) > -1}
                  onChange={() => setSelectedMarkets([tag])}
                  style={{ fontSize: "14px", marginLeft: "30px" }}
                >
                  {tag}
                </CheckableTag>
              ))}
            </div>
            <div
              style={{
                borderBottom: "2px dotted #f0f0f0",
                marginBottom: "20px",
              }}
            >
              <Form
                form={form}
                name="advanced_search"
                className="ant-advanced-search-form"
              >
                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item label="Mã đơn hàng">
                      <Input placeholder="Nhập mã đơn hàng" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Tài khoản thanh toán">
                      <Select
                        showSearch
                        placeholder="Chọn tài khoản thanh toán"
                        onChange={(value) => {
                          setSelectedPaymentAccount(value)
                        }}
                      >
                        {userProfile?.accounts?.map((account) => (
                          <Option key={account} value={account}>
                            {account}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Thời gian yêu cầu">
                      <DatePicker
                        onChange={(date) => {
                          setSelectedDay(moment(date).format("YYYYMMDD"))
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <div>
              <Row>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={loadOrdersList}
                  >
                    Tìm kiếm
                  </Button>
                  <Button
                    style={{ margin: "0 8px" }}
                    onClick={handleClearFilters}
                  >
                    <SyncOutlined />
                    Làm mới bộ lọc
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Card>
      </div>
      <div>
        <Card style={{ marginTop: "20px" }}>
          <div
            style={{ margin: "20px 0", textAlign: "center", fontSize: "24px" }}
          >
            <h1>Danh sách Yêu cầu Hoàn tiền</h1>
          </div>
          <div>
            <Table
              columns={columns}
              expandable={{
                expandedRowRender: (record) => (
                  <p
                    style={{
                      margin: 0,
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <UserDisplay
                      uid={record.customerId}
                      displayAvatar={true}
                      displayRole={false}
                    />
                    đã tạo lúc{" "}
                    {moment(record.createAt).format("HH:mm:ss DD-MM-YYYY")}
                  </p>
                ),
                rowExpandable: (record) =>
                  record.customerId !== "Not Expandable",
              }}
              dataSource={orderList}
              pagination={false}
              rowKey={(record) => record._id}
              scroll={{
                x: 1300,
              }}
            />
            <div className="mb-20">
              <Pagination
                total={pagination.totalItemCount}
                pageSize={pageSize}
                onShowSizeChange={(current, size) => {
                  setPageSize(size)
                }}
                showSizeChanger
                current={currentPage}
                onChange={(page) => {
                  setCurrentPage(page)
                }}
              />
            </div>
          </div>
        </Card>
        <Modal
          title="Tạo yêu cầu hoàn tiền"
          visible={modalRefundRequest}
          footer={
            <div>
              <Button onClick={() => setModalRefundRequest(false)}>Hủy</Button>
              <Button type="primary" onClick={handleSendRefundRequest}>
                Tạo
              </Button>
            </div>
          }
        >
          <Form>
            <Form.Item>
              <Row>
                <Col span={8}>
                  <label> Tài khoản thanh toán: </label>
                </Col>
                <Col span={16}>
                  <Input value={selectedOrder?.pickedAccount} disabled />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row>
                <Col span={8}>
                  <label> Mã đơn hàng: </label>
                </Col>
                <Col span={16}>
                  <Input value={selectedOrder?.orderNumber} disabled />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row>
                <Col span={8}>
                  <label> Ngày hoàn: </label>
                </Col>
                <Col span={16}>
                  <DatePicker
                    showTime
                    style={{ width: "100%" }}
                    onChange={(date) => {
                      setRefundDate(moment(date).format("YYYY-MM-DD HH:mm:ss"))
                    }}
                  />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row>
                <Col span={8}>
                  <label> Số tiền: </label>
                </Col>
                <Col span={16}>
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Nhập số tiền"
                    value={selectedOrder?.amount}
                    onChange={(value) => {
                      if (value > selectedOrder?.amount) {
                        message.error(
                          "Số tiền không được lớn hơn số tiền đã thanh toán"
                        )
                        setRefundAmount(selectedOrder?.amount)
                      } else {
                        setRefundAmount(value)
                      }
                    }}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  )
}
