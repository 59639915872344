import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Table,
  Tag,
} from "antd"
import React, { useEffect, useState } from "react"
import axiosInstance from "../../utils/axios.util"
import { FilterOutlined } from "@ant-design/icons"
import moment from "moment"

const { Option } = Select

function TransactionTypeTag(props) {
  switch (props.transactionType) {
    case "top_up":
      return <Tag color="#87d068">Nạp tiền</Tag>
    case "withdraw":
      return <Tag color="default">Rút tiền</Tag>
    case "order_created":
      return <Tag color="#F54054">Thanh toán đơn hàng</Tag>
    case "order_refund":
      return <Tag color="#faad14">Hoàn tiền đơn hàng</Tag>
  }

  return <Tag color="default">{props.transactionType}</Tag>
}

TransactionTypeTag.propTypes = {}
export default function UserTransaction() {
  const [showFilter, setShowFilter] = useState(false)
  const [transactionList, setTransactionList] = useState([])
  const [transactionType, setTransactionType] = useState("")
  const loadTransactionList = () => {
    axiosInstance
      .get("/user/transactions", {
        params: {
          type: transactionType,
        },
      })
      .then((res) => {
        setTransactionList(res.data.items)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    loadTransactionList()
  }, [])

  const handleShowFilter = () => {
    setShowFilter(!showFilter)
  }
  const columns = [
    {
      title: "Ngày",
      dataIndex: "createdAt",
      key: "date",
      render: (value) => (
        <span>{moment(value).format("DD/MM/YYYY HH:mm:ss")}</span>
      ),
    },
    {
      title: "Loại giao dịch",
      dataIndex: "transactionType",
      key: "type",
      render: (value) => <TransactionTypeTag transactionType={value} />,
    },
    {
      title: "Số tiền",
      dataIndex: "amount",
      key: "amount",
      render: (value, transaction) => (
        <span>
          {transaction.direction === "out" ? "- " : "+ "}
          {new Intl.NumberFormat("en-us", {
            style: "currency",
            currency: "VND",
          }).format(value)}{" "}
        </span>
      ),
    },
    {
      title: "Nội dung giao dịch",
      dataIndex: "description",
      key: "description",
      render: (value) => (
        <span>{value === "Nạp tiền" ? "Admin nạp tiền" : value}</span>
      ),
    },
    {
      title: "Số dư",
      dataIndex: "balance",
      key: "balance",
      render: (value) => (
        <span>
          {new Intl.NumberFormat("en-us", {
            style: "currency",
            currency: "VND",
          }).format(value)}{" "}
        </span>
      ),
    },
  ]
  return (
    <>
      <Card title="LỊCH SỬ GIAO DỊCH">
        <Button type="primary" onClick={handleShowFilter}>
          <FilterOutlined />
          Lọc
        </Button>
        {showFilter && (
          <Form>
            <Row className="mb-20" gutter={16}>
              <Col xs={24} md={12}>
                <Row>
                  <Col span={24}>
                    <label>Ngày giao dịch: </label>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="Từ ngày"
                    />
                  </Col>
                  {/* <Col xs={24} md={4}>
                  <span> - </span>
                </Col> */}
                  <Col xs={24} md={12}>
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="Đến ngày"
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={24} md={12}>
                <label>Trạng thái: </label>
                <Select
                  showSearch
                  onChange={setTransactionType}
                  value={transactionType}
                  style={{ width: "100%" }}
                >
                  <Option value="">Tất cả</Option>
                  <Option value="top_up">Nạp tiền</Option>
                  <Option value="withdrawal">Rút tiền</Option>
                  <Option value="entrust_payment_order_created">
                    Thanh toán đơn hàng
                  </Option>
                  <Option value="order_refund">Hoàn tiền đơn hàng</Option>
                </Select>
              </Col>
            </Row>
            <Row className="mb-20">
              <Col span={24} style={{ textAlign: "right" }}>
                <Button type="primary" onClick={loadTransactionList}>
                  Tìm kiếm
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Card>
      <div className="mb-20">
        <Card>
          <Table
            columns={columns}
            dataSource={transactionList}
            rowKey={(record) => record.id}
          ></Table>
        </Card>
      </div>
    </>
  )
}
